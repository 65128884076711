import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import useWindowSize from '../../hooks/useWindowSize';
import DarkMode from '../darkmode'; 
import Header from '../header';
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { getHeader, headerSelector } from "../../redux/slicers/headerSlice";
import IconTw from '../assets/images/icon-tw.svg';
import IconTwHover from '../assets/images/icon-tw-hover-new.svg';
import IconTele from '../assets/images/icon-telegram.svg';
import IconTeleHover from '../assets/images/icon-telegram-hover-new.svg';
import IconDisco from '../assets/images/icon-disco.svg';
import IconDiscoHover from '../assets/images/icon-disco-hover-new.svg';
import FLogo from '../assets/images/flogo-sidebar.svg';
import { getBLSPriceMexc, priceSelector } from "../../redux/slicers/priceSlice";

type SideBarChildProps = {
    toggle: boolean;
    setToggle: (setToggle: boolean) => void;
}

interface Size {
    width: number;
    height: number;
}

const SideBar: React.FC<SideBarChildProps> = (props: SideBarChildProps) => {    
    const [showSubmenu, setShowSubmenu] = useState(0);
    const { header } = useAppSelector(headerSelector);
    const size: Size = useWindowSize();
    let location = useLocation();
    let myPathname = location.pathname;
    const sep = /\//;
    let isPathname = myPathname.split(sep);
    const dispatch = useAppDispatch() 
    const { BLSPriceMexc } = useAppSelector(priceSelector);

    const handleClose = () => { 
      dispatch(getHeader(""));
    }
    const toggle = props.toggle;

    const setToggleSp = () => {
        if(size.width < 991) {
            props.setToggle(false);
        }
    }

    useEffect(() => {
        dispatch(getBLSPriceMexc());
    }, []);

    return(
        <>   
        <div className={`c-sideBar ${toggle ? size.width > 991 ? 'is-open' : 'is-sp' : ''}`}>   
        {header === "create" || header === "connectNetwork" || header === "connectWallet" || header === "infoWallet" ?  <div onClick={() => handleClose()} className="overplay"/> :null}         
            <div className="c-nav">
                <ul>
                    <li>
                        <Link to="" className={myPathname === '/' ? 'is-active' : ''} onClick={() => {setShowSubmenu(0);setToggleSp()}}>
                            <span className="c-nav__icon"><img src={window.location.origin + '/images/sideBar-icon-home.svg'} alt="" /></span>
                            <span className="c-nav__txt">Home</span>
                        </Link>
                    </li>
                    <li>
                        <span className={`is-sub${isPathname[1] === 'launchpad' || isPathname[1] === 'launchpad-edit' ? ' is-active' : ''}${showSubmenu === 1 ? ' is-open' : ''}`} onClick={() => setShowSubmenu(1)}>
                            <span className="c-nav__icon"><img src={window.location.origin + '/images/sideBar-icon-launchpad.png'} alt="" /></span>
                            <span className="c-nav__txt">Launchpads</span>
                        </span>
                        <ul className={`c-nav__sub ${showSubmenu === 1 ? 'is-open' : ''}`}>
                            <li onClick={() => {setToggleSp()}}><Link className={myPathname === '/launchpad/list' ? 'is-active' : ''} to="/launchpad/list">Launchpads List</Link></li>
                            <li onClick={() => {setToggleSp()}}><Link className={myPathname === '/launchpad/my-launchpad' ? 'is-active' : ''} to="/launchpad/my-launchpad">My Launchpads</Link></li>
                            <li onClick={() => {setShowSubmenu(0);setToggleSp()}}><Link className={myPathname === '/launchpad/create-launchpad' && location.search === '?type=1' ? 'is-active' : ''} to="/launchpad/create-launchpad?type=1">Create Presale</Link></li>
                            <li onClick={() => {setShowSubmenu(0);setToggleSp()}}><Link className={myPathname === 'launchpad/create-fairlaunch' ? 'is-active' : ''} to="launchpad/create-fairlaunch">Create Fair Launch</Link></li>
                        </ul>
                    </li>
                    <li>
                        <span className={`is-sub ${isPathname[1] === 'private-sale' ? 'is-active' : ''} ${showSubmenu === 2 ? 'is-open' : ''}`} onClick={() => setShowSubmenu(2)}>
                            <span className="c-nav__icon"><img src={window.location.origin + '/images/sideBar-icon-privateSale.svg'} alt="" /></span>
                            <span className="c-nav__txt">Private Sale</span>
                        </span>
                        <ul className={`c-nav__sub ${showSubmenu === 2 ? 'is-open' : ''}`}>
                            <li onClick={() => {setToggleSp()}}><Link className={myPathname === '/private-sale/list' ? 'is-active' : ''} to="/private-sale/list">Private Sale List</Link></li>
                            <li onClick={() => {setToggleSp()}}><Link className={myPathname === '/private-sale/my-private' ? 'is-active' : ''} to="/private-sale/my-private">My Private Sale</Link></li>
                            <li onClick={() => {setToggleSp()}}><Link className={myPathname === '/private-sale/create' ? 'is-active' : ''} to="/private-sale/create">Create Private Sale</Link></li>
                        </ul>
                    </li>
                    
                    <li>
                        <span className={`is-sub ${isPathname[1] === 'token' ? 'is-active' : ''} ${showSubmenu === 3 ? 'is-open' : ''}`} onClick={() => setShowSubmenu(3)}>
                            <span className="c-nav__icon"><img src={window.location.origin + '/images/sideBar-icon-goldLock.svg'} alt="" /></span>
                            <span className="c-nav__txt">Token</span>
                        </span>
                        <ul className={`c-nav__sub ${showSubmenu === 3 ? 'is-open' : ''}`}>
                            <li onClick={() => {setToggleSp()}}><Link className={myPathname === '/token/my-token' ? 'is-active' : ''} to="/token/my-token">My Token List</Link></li>
                            <li onClick={() => {setToggleSp()}}><Link className={myPathname === '/token/create-lock' ? 'is-active' : ''} to="/token/create-lock">Create Token Lock</Link></li>
                            <li onClick={() => {setToggleSp()}}><Link className={myPathname === '/token/create-token' ? 'is-active' : ''} to="/token/create-token">Create Token</Link></li>
                        </ul>
                    </li>
                    

                    <li>
                        <span className={`is-sub ${isPathname[1] === 'liquidity' ? 'is-active' : ''} ${showSubmenu === 4 ? 'is-open' : ''}`} onClick={() => setShowSubmenu(4)}>
                            <span className="c-nav__icon"><img src={window.location.origin + '/images/sideBar-icon-liquidity.svg'} alt="" /></span>
                            <span className="c-nav__txt">Liquidity</span>
                        </span>
                        <ul className={`c-nav__sub ${showSubmenu === 4 ? 'is-open' : ''}`}>
                            <li onClick={() => {setToggleSp()}}><Link className={myPathname === '/liquidity' ? 'is-active' : ''} to="/liquidity">My Liquidity Lock</Link></li>
                            <li onClick={() => {setToggleSp()}}><Link className={myPathname === '/liquidity/create-liquidity' ? 'is-active' : ''} to="/liquidity/create-liquidity">Create LP Token Lock</Link></li>
                        </ul>
                    </li>
                    <li>
                        <span className={`is-sub ${isPathname[1] === 'airdrop' ? 'is-active' : ''} ${showSubmenu === 5 ? 'is-open' : ''}`} onClick={() => setShowSubmenu(5)}>
                            <span className="c-nav__icon"><img src={window.location.origin + '/images/sideBar-icon-airdrop.svg'} alt="" /></span>
                            <span className="c-nav__txt">Airdrop</span>
                        </span>
                        <ul className={`c-nav__sub ${showSubmenu === 5 ? 'is-open' : ''}`}>
                            <li onClick={() => {setShowSubmenu(0);setToggleSp()}}><Link to="/airdrop/list">Airdrop List</Link></li>
                            <li onClick={() => {setShowSubmenu(0);setToggleSp()}}><Link to="/airdrop/create">Create Airdrop</Link></li>
                        </ul>
                    </li>
                    <li>
                        <Link to="/leaderboard" className={isPathname[1] === 'leaderboard' ? 'is-active' : ''} onClick={() => {setShowSubmenu(0);setToggleSp()}}>
                            <span className="c-nav__icon"><img src={window.location.origin + '/images/sideBar-icon-leaderBoard.svg'} alt="" /></span>
                            <span className="c-nav__txt">Leaderboard</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/anti-bot" className={isPathname[1] === 'anti-bot' ? 'is-active' : ''} onClick={() => {setShowSubmenu(0);setToggleSp()}}>
                            <span className="c-nav__icon"><img src={window.location.origin + '/images/sideBar-icon-antiBot.svg'} alt="" /></span>
                            <span className="c-nav__txt">Anti-Bot</span>
                        </Link>
                    </li>
                    <li>
                        <span className={`is-sub ${isPathname[1] === 'bls' ? 'is-active' : ''} ${showSubmenu === 5 ? 'is-open' : ''}`} onClick={() => setShowSubmenu(6)}>
                            <span className="c-nav__icon"><img src={window.location.origin + '/images/sideBar-icon-xBLS.svg'} alt="" /></span>
                            <span className="c-nav__txt">xBLS</span>
                        </span>
                        <ul className={`c-nav__sub ${showSubmenu === 6 ? 'is-open' : ''}`}>
                            <li onClick={() => {setToggleSp()}}><Link className={myPathname === '/bls/dashboard' ? 'is-active' : ''} to="/bls/dashboard">Dashboard</Link></li>
                            <li onClick={() => {setToggleSp()}}><Link className={myPathname === '/bls/dividends' ? 'is-active' : ''} to="/bls/dividends">Dividends</Link></li>
                        </ul>
                    </li>
                    <li>
                        <Link to="/multi-sender" className={isPathname[1] === 'multi-sender' ? 'is-active' : ''} onClick={() => {setShowSubmenu(0);setToggleSp()}}>
                            <span className="c-nav__icon"><img src={window.location.origin + '/images/sideBar-icon-multiSender.svg'} alt="" /></span>
                            <span className="c-nav__txt">Multi-Sender</span>
                        </Link>
                    </li>
                    {/* <li>
                        <Link to="" className={myPathname === 'pools-alert' ? 'is-active' : ''} onClick={() => {setShowSubmenu(0);setToggleSp()}}>
                            <span className="c-nav__icon"><img src="../images/sideBar-icon-poolsAlert.svg" alt="" /></span>
                            <span className="c-nav__txt">Pools Alert</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="" className={myPathname === 'kyc-audit' ? 'is-active' : ''} onClick={() => {setShowSubmenu(0);setToggleSp()}}>
                            <span className="c-nav__icon"><img src="../images/sideBar-icon-kyc.svg" alt="" /></span>
                            <span className="c-nav__txt">KYC & Audit</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="" className={myPathname === 'docs' ? 'is-active' : ''} onClick={() => {setShowSubmenu(0);setToggleSp()}}>
                            <span className="c-nav__icon"><img src="../images/sideBar-icon-docs.svg" alt="" /></span>
                            <span className="c-nav__txt">Docs</span>
                        </Link>
                    </li> */}
                </ul>
            </div>
            <div className="c-sideBar__footer"> 
                {size.width < 991 &&  <Header tabMobile={true} toggle={toggle}/>} 
                <ul className="c-sideBar__media">
                    <li><a target="_blank" href='https://twitter.com/BluesaleBls'>
                        <img src={IconTw} alt="" />
                        <img src={IconTwHover} alt="" />
                    </a></li>
                    <li><a target="_blank" href='https://t.me/BlueSaleFinance'>
                        <img src={IconTele} alt="" />
                        <img src={IconTeleHover} alt="" />
                    </a></li>
                    <li><a target="_blank" href='https://discord.io/BlueSale'>
                        <img src={IconDisco} alt="" />
                        <img src={IconDiscoHover} alt="" />
                    </a></li>
                    {/* <li><a href='/'>
                        <img src="../images/icon-youtube.svg" alt="" />
                        <img src="../images/icon-youtube-hover.svg" alt="" />
                    </a></li> */}
                </ul>
                <p className="c-sideBar__flogo">
                    <img src={FLogo} alt="BlueSale" />
                    <span>{BLSPriceMexc ? Number(BLSPriceMexc)+'$' : 'N/A'}</span>
                </p>
                {/* <div className="c-language">
                    <span className="c-language__icon"><img src="../images/icon-language.svg" alt="" /></span>
                    <span className="c-language__txt">English</span>
                </div>
                <DarkMode /> */}
            </div>
        </div>
        </> 
    )
}

export default SideBar;